body {
  position: relative;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Marcellus SC', 'Legan', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #e0e0e0;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Marcellus SC';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/marcellussc/v13/ke8iOgUHP1dg-Rmi6RWjbLE_iNacKKg.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Marcellus SC';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/marcellussc/v13/ke8iOgUHP1dg-Rmi6RWjbLE_htac.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Legan';
  font-style: italic;
  font-weight: 500;
  src: url(/public/assets/Legan.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Legan';
  font-style: italic;
  font-weight: 500;
  src: url(/public/assets/Legan.woff) format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}


li {

  /* Убираем нумерацию */

  list-style-type: none !important;

  /* Оставляем нумерацию в браузере IE6 и ниже */

  list-style-type: decimal;

}

/* Инициируем счетчик */

ol {

  counter-reset: list1;

}

ol li:before {

  /* Увеличиваем значение счетчика */

  counter-increment: list1;

  /* Выводим значение */

  content: counter(list1) ". ";

}

ol ol {

  /* Инициируем счетчик вложенного списка */

  counter-reset: list2; }

ol ol li:before {

  /* Увеличиваем значение счетчика */

  counter-increment: list2;

  /* Выводим значение типа 2.1, 2.2,... */

  content: counter(list1) "." counter(list2) ". ";

}