.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.no-scroll {
    overflow: hidden;
}

.dark-area {
    padding: 40px;
    background-color: rgba(14,14,14,.92);
}

.review-link {
    padding-right: 5px;
    padding-bottom: 5px;
    margin: auto;
}

@media (min-width: 991px) {
    .adaptive-height {
        height: 370px;
    }
}

@media (max-width: 830px) {
    .adaptive-height {
        height: 340px;
    }
}

@media (max-width: 355px) {
    .adaptive-height {
        height: 320px;
        padding: 5px;
    }
}

.scroll-to-top {
    opacity: 0;
    transition: opacity 0.3s ease-in-out, bottom 0.3s ease-in-out; /* Анимация для смещения и непрозрачности */
}

.scroll-to-top.visible {
    bottom: 20px;
    opacity: 1;
}
